body {
  font-size: 16px !important;
  overflow: hidden;
}
/* body::-webkit-scrollbar {
  display: none;
} */

.css-13dtvj-MuiDrawer-docked .MuiDrawer-paper {
  background: #4a148c;
}
.css-ooaiwv-MuiDrawer-docked .MuiDrawer-paper {
  background: #4a148c;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #f8f2f2;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background: #4a148c !important;
}
.css-16w7eh8-MuiButtonBase-root-MuiListItemButton-root {
  margin-top: 23px !important;
}
.css-h4y409-MuiList-root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 -9px !important;
}

.login-fm label {
  font-size: 18px !important;
  color: black !important;
  font-weight: bold !important;
  padding: 0 5px !important;
}

.textfield {
  border: none !important;
}

.active_common {
  text-align: center;
  font-weight: lighter;
  font-size: 16px;
  padding: 1rem;
  line-height: 2.5em;
}

.list-gm {
  background: none;
  color: #fff;
}

.active {
  background: #2b2e4b;
  opacity: 1.3;
  color: white !important;
  width: 240px;
  margin-top: 14px;
}

.css-14qavys,
.css-15kv9nb,
.css-15kv9nb {
  margin: -7px !important;
}
.css-1ontqvh {
  padding: 19px 0px !important;
}
#demo-simple-select-label {
  color: white;
  font-size: 16px;
}

#demo-simple-select {
  border-bottom: 2px solid white;
  background: #2b2e4b;
  color: #fff;
  padding: 4px 9px 7px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.layout_background {
  background-image: url("../src/assets/background-image.png");
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-attachment: fixed;
  margin-top: 55px;
}

.card {
  color: #f27bfa;
  font-weight: bold;
}
.action_card {
  text-shadow: 2px 2px 8px #f27bfa;
  width: 250px;
  height: 90px;
  background: white;
  padding: 30px 38px;
  font-weight: 800;
  display: hidden;
  -webkit-clip-path: polygon(
    77% 19%,
    100% 50%,
    80% 81%,
    0 82%,
    11% 50%,
    1% 19%
  );
  clip-path: polygon(77% 19%, 100% 50%, 80% 81%, 0 82%, 11% 50%, 1% 19%);
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.buttons:hover {
  background-color: #b3d4fc; /* Change to the desired hover background color */
}

.text_heading {
  position: absolute;
  right: 160px;
  top: 9px;
  color: #1b1e22a7;
  font-weight: 900;
  padding: 6px;
  border-radius: 20px;
  font-family: "Calligraffitti", cursive;
  font-weight: 900;
  font-size: 2.75rem;
  text-decoration: underline;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  top: 0px !important;
}

@media only screen and (max-width: 1280px) {
  .text_heading {
    display: none;
  }

  .charts {
    width: 800px;
    height: 350px;
  }
}

@media only screen and (max-width: 1280px) {
  .charts {
    position: relative;
    left: 220px;
    width: 100%;
    height: 350px;
  }
}

.action_items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.accordian {
  display: flex;
  flex-direction: column;
}

.accordian:nth-child(odd) {
  background: rgba(247, 196, 196, 0.836);
  color: black;
  font-weight: bold;
  text-align: left;
  line-height: 1.5rem;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: none;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Adjust as needed */
}

.arenaCard {
  width: 23%;
  margin: 1%;
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.arenaCard h6 {
  margin-bottom: 10px;
}

/* Add these styles to your stylesheet or component */

/* Flip effect */
.flip-card:hover .front {
  transform: rotateY(180deg);
}

.flip-card:hover .back {
  transform: rotateY(0deg);
}

/* Preserve 3D transformations */
.transform-style {
  transform-style: preserve-3d;
}

/* Hide the back initially */
.back {
  transform: rotateY(180deg);
}

/* Make the back visible on hover */
.flip-card:hover .back {
  transform: rotateY(0deg);
}

/* Ensure both front and back have transition effect */
.transition {
  transition: transform 0.3s ease;
}

/* Modal styles */
/* Your existing styles for buttons */

/* Modal styles */
.modal {
  position: fixed;
  top: 5em;
  left: 4em;
  width: 97%;
  height: 93%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 35%; /* Set your desired width */
  height: 30%; /* Set your desired height */
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.close {
  position: absolute;
  top: 15px;
  right: 61px;
  font-size: 43px;
  cursor: pointer;
  color: white; /* Change to the color you want for the close button */
}
.six20 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: sans-serif;
  font-size: 9rem;
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.css-1l8j5k8 {
  background: #4a148c !important;
}
.css-h4y409-MuiList-root {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 -9px !important;
}
/* Add any additional styling for your cards */

/* color: #4044b3; - light blue
color: #2b2e4b; - dark blue
color: #f3c7ff; -light pink
color: #f27bfa; - dark pink */

/* #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722 */
