@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-layout {
    display: grid;
    gap: 1rem;

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
